@tailwind base;
@tailwind components;
@tailwind utilities;
* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

.text-ellipsis {
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.read-msgs {
  color: #4caf50;
}

.reads-msgs {
  color: #f44336;
}

.toast {
  position: fixed;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
}

#tab button:focus {
  box-shadow: none;
}
