.actives {
  background-color: #6eb5ff;
  color: white !important;
  font-weight: 800 !important;
}

.activess {
  background-color: silver;
  color: white !important;
  font-weight: 800 !important;
}

.actives:hover {
  background-color: #6eb5ff;
}

.actives svg {
  color: white !important;
}

.activess svg {
  color: white !important;
}

.side-bar svg {
  width: 20px;
  height: 15px;
  color: #6eb5ff;
}

.side-bar span {
  color: #0f2e4f !important;
  font-weight: 500;
}

.active-dropdown {
  background-color: #0078e7;
  color: white;
  font-weight: 700;
}

.active-dropdown:hover {
  color: black;
}

.scrollbar-always-visible::-webkit-scrollbar {
  width: 6px !important;
}

.scrollbar-always-visible::-webkit-scrollbar-thumb {
  background-color: rgba(0, 120, 231, 0.5) !important;
  border-radius: 6px !important;
}
